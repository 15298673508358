.ios-parent-vstack {
    padding-top: 50px;
    padding-bottom: 15px;
    touch-action: none;
}
.ios-share-sheet-parent-vstack {
    padding-top: 10px;
    padding-bottom: 15px;
    touch-action: none;
}
.image-gallery-slides {
    border-radius: 10px; /* Adjust the radius as needed */
}